.project-tile {

	.section-inner {
		padding-top: $project-tile--padding-t__mobile;
		padding-bottom: $project-tile--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $project-tile-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $project-tile-items--padding__mobile != null ) {
			margin-right: -($project-tile-items--padding__mobile / 2);
			margin-left: -($project-tile-items--padding__mobile / 2);
			margin-top: -($project-tile-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($project-tile-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($project-tile-items--padding__mobile / 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $project-tile-item--width;
				max-width: $project-tile-item--width;
				@if ( $project-tile-items--padding__mobile != null ) {
					padding-left: $project-tile-items--padding__mobile / 2;
					padding-right: $project-tile-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		@if ( $project-tile-items--padding__mobile != null ) {
			padding: $project-tile-items--padding__mobile / 2;
		}
	}

	

	.tiles-item-inner {
		border-radius: 8px;
		padding-top: $project-tile-item--inner-padding-v;
		padding-bottom: $project-tile-item--inner-padding-v;
		padding-left: $project-tile-item--inner-padding-h;
		padding-right: $project-tile-item--inner-padding-h;
	}
}

.project-tile-item-image{
	width:100%;
	height:150px;
	transition: 0.3s ease;
	object-fit: cover!important;
}


.project-tile-item-content {
	flex-grow: 1;
	text-align: justify;
}

.project-tile-item-title{
	text-align: center;
}

.project-tile-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}



@include media('>980px'){
	.project-tile {
		.tiles-item {
			flex-basis: $project-tile-item--width;
			max-width: $project-tile-item--width;
		}
	}
}
@include media('>1100px'){
	.project-tile {
		.tiles-item {
			flex-basis: $project-tile-item--width-xl;
			max-width: $project-tile-item--width-xl;
		}
	}
}

@include media('<950px'){
	.project-tile {
		.tiles-item {
			flex-basis: $project-tile-item--width-m;
			max-width: $project-tile-item--width-m;
		}
	}
}


@include media('<750px'){
	.project-tile {
		.tiles-item {
			max-width: 600px!important;
			margin-left: 42px;
			margin-right: 42px;
			flex-basis: $project-tile-item--width-s;
			max-width: $project-tile-item--width-s;
		}
	}
	.project-tile-item-image{
		height:220px;
	}
}

@include media( '>medium' ) {

	.project-tile {

		.section-inner {
			padding-top: $project-tile--padding-t__desktop;
			padding-bottom: $project-tile--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $project-tile-header--padding__desktop;
		}

		@if ( $project-tile-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($project-tile-items--padding__desktop / 2);
				margin-left: -($project-tile-items--padding__desktop / 2);
				margin-top: -($project-tile-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($project-tile-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($project-tile-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						padding-left: $project-tile-items--padding__desktop / 2;
						padding-right: $project-tile-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				padding: $project-tile-items--padding__desktop / 2;
			}
		}
	}
}

//animation
.project-tile-item-inner {
	transition: transform 250ms;
}


.project-tile-item-inner:hover{
	transform:scale(1.05, 1.05);
	cursor: pointer;
}