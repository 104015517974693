.project-tile {

	.tiles-item-inner {
		background: get-color(dark, 2);		
	}
}

.project-tile-line-clamp{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; 
    overflow: hidden;
}

.project-tile-item-content {
    position: relative;
}

.project-tile-item-footer {
    font-weight: 600;
    padding-top: 20px;

    &.has-top-divider {

        &::before {
            background: get-color(dark, 3);
        }
    }
}

.project-tile-item-link {
	color: get-color(secondary, 3);

	a {
        color: get-color(secondary, 3);
        text-decoration: none;

        &:hover {
            color: get-color(secondary, 2);
        }
	}
}