// Features tiles (core/sections/_features-tiles.scss) ---------
$skill-tiles--padding-t__mobile: 		null;		// features tiles, top padding (mobile)
$skill-tiles--padding-b__mobile:			null;		// features tiles, bottom padding (mobile)
$skill-tiles--padding-t__desktop: 		40px;		// features tiles, top padding (desktop)
$skill-tiles--padding-b__desktop: 		null;		// features tiles, bottom padding (desktop)
$skill-tiles-header--padding__mobile: 	null;		// features tiles header, bottom padding (mobile)
$skill-tiles-header--padding__desktop: 	20px;		// features tiles header, bottom padding (desktop)
$skill-tiles-items--padding__mobile: 	16px;		// padding between features tiles (mobile)
$skill-tiles-items--padding__desktop: 	32px;		// padding between features tiles (desktop)
$skill-tiles-item--max-width: 			190px;		// features tiles item, max-width
$skill-tiles-item--min-width: 			150px;		// features tiles item, min-width
$skill-tiles-item--inner-padding-t: 		16px;			// features tiles item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$skill-tiles-item--inner-padding-b: 		16px;			// features tiles item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$skill-tiles-item--inner-padding-h: 		0;			// features tiles item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
