$scroll-reveal--transition-duration: 0.8s;
$scroll-reveal--transition-timing: cubic-bezier(0.39, 0.575, 0.565, 1);
$scroll-reveal--from-top-amount: -10px;
$scroll-reveal--from-bottom-amount: 10px;
$scroll-reveal--from-left-amount: -10px;
$scroll-reveal--from-right-amount: 10px;
$scroll-reveal--scale-up-amount: 0.95%;
$scroll-reveal--scale-down-amount: 1.05%;
$scroll-reveal--rotate-from-left-amount: -45deg;
$scroll-reveal--rotate-from-right-amount: 45deg;
