.logo-round {
    border-color:get_color(primary, 1);
    border-radius:9999px;
    border-width:4px;
    border-top-style:solid;
    border-bottom-style:solid;
    border-left-style:solid;
    border-right-style:solid;

    width:148px;
}

.logo-round-above-container{
    margin-top:-3rem;
}