.skill-tiles {

	//animation
	.tiles-item-inner {
		transition: transform 250ms;
	}

	.tiles-item-inner:hover{
		transform:scale(1.05, 1.05);
		//cursor: pointer;
	}

	.section-inner {
		padding-top: $skill-tiles--padding-t__mobile;
		padding-bottom: $skill-tiles--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $skill-tiles-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $skill-tiles-items--padding__mobile != null ) {
			margin-right: -($skill-tiles-items--padding__mobile / 2);
			margin-left: -($skill-tiles-items--padding__mobile / 2);
			margin-top: -($skill-tiles-items--padding__mobile / 2);

			&:last-of-type {
				margin-bottom: -($skill-tiles-items--padding__mobile / 2);
			}

			&:not(:last-of-type) {
				margin-bottom: ($skill-tiles-items--padding__mobile / 2);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $skill-tiles-item--min-width;
				max-width: $skill-tiles-item--min-width;
				@if ( $skill-tiles-items--padding__mobile != null ) {
					padding-left: $skill-tiles-items--padding__mobile / 2;
					padding-right: $skill-tiles-items--padding__mobile / 2;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: $skill-tiles-item--min-width;
		max-width: $skill-tiles-item--min-width;
		@if ( $skill-tiles-items--padding__mobile != null ) {
			padding: $skill-tiles-items--padding__mobile / 2;
		}
	}

	.tiles-item-inner {
		background-color: get-color(dark, 2);
		border-radius: 20px;
		padding-top: $skill-tiles-item--inner-padding-t;
		padding-bottom: $skill-tiles-item--inner-padding-b;
		padding-left: $skill-tiles-item--inner-padding-h;
		padding-right: $skill-tiles-item--inner-padding-h;
	}
}

@include media( '>medium' ) {

	.skill-tiles {

		.section-inner {
			padding-top: $skill-tiles--padding-t__desktop;
			padding-bottom: $skill-tiles--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $skill-tiles-header--padding__desktop;
		}

		@if ( $skill-tiles-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($skill-tiles-items--padding__desktop / 2);
				margin-left: -($skill-tiles-items--padding__desktop / 2);
				margin-top: -($skill-tiles-items--padding__desktop / 2);

				&:last-of-type {
					margin-bottom: -($skill-tiles-items--padding__desktop / 2);
				}

				&:not(:last-of-type) {
					margin-bottom: ($skill-tiles-items--padding__desktop / 2);
				}

				&.push-left {

					&::after {
						flex-basis: $skill-tiles-item--max-width;
						max-width: $skill-tiles-item--max-width;
						padding-left: $skill-tiles-items--padding__desktop / 2;
						padding-right: $skill-tiles-items--padding__desktop / 2;
					}
				}
			}

			.tiles-item {
				flex-basis: $skill-tiles-item--max-width;
				max-width: $skill-tiles-item--max-width;
				padding: $skill-tiles-items--padding__desktop / 2;
			}
		}
	}
}
