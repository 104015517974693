// project-tile (core/sections/_project-tile.scss) ---------------
$project-tile--padding-t__mobile: 		    null;		// project-tile, top padding (mobile)
$project-tile--padding-b__mobile: 		    null;		// project-tile, bottom padding (mobile)
$project-tile--padding-t__desktop: 		    20px;		// project-tile, top padding (desktop)
$project-tile--padding-b__desktop: 		    null;		// project-tile, bottom padding (desktop)
$project-tile-header--padding__mobile: 	    null;		// project-tile header, bottom padding (mobile)
$project-tile-header--padding__desktop:	    null;		// project-tile header, bottom padding (desktop)
$project-tile-items--padding__mobile: 	    null;		// padding between project-tile items (mobile)
$project-tile-items--padding__desktop: 	    null;		// padding between project-tile items (desktop)
$project-tile-item--width-xl: 			    22.5%;		// project-tile item, width
$project-tile-item--width: 				    200px;		// project-tile item, width
$project-tile-item--width-m: 			    325px;		// project-tile item, width
$project-tile-item--width-s: 			    100%;		// project-tile item, width
$project-tile-item--inner-padding-v: 	    16px;		// project-tile item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$project-tile-item--inner-padding-h: 	    16px;		// project-tile item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
